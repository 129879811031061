/*
 * https://www.tints.dev/
 * https://m2.material.io/design/color/the-color-system.html#color-theme-creation
 */

import { Metadata } from 'next'

import { APP_URL } from './constants/env'
import { Theme } from './context/theme/theme.types'

export const defaultTheme: Theme = Theme.DARK

export const colors = {
  light: {
    primary: '#f2f2f2',
    'primary-light': '#ffffff',
    'primary-dark': '#8C8C8C', // Not used anywhere yet but part of material design
    secondary: '#f2f2f2', // primary buttons
    surface: '#0f0e18',
    background: '#382e4d',
    error: '#b00020', // Not used anywhere yet but part of material design
    on: {
      primary: '#0D0D0D',
      secondary: '#0D0D0D',
      surface: '#f2f2f2',
      background: '#262626',
      error: '#f2f2f2', // Not used anywhere yet but part of material design
    },
  },
  dark: {
    primary: '#ffffff',
    'primary-light': '#ffffff',
    'primary-dark': '#8C8C8C', // Not used anywhere yet but part of material design
    secondary: '#ffffff', // primary buttons
    surface: '#0e0e11',
    background: '#262626',
    error: '#b00020', // Not used anywhere yet but part of material design
    on: {
      primary: '#0D0D0D',
      secondary: '#0D0D0D',
      surface: '#ffffff',
      background: '#f2f2f2',
      error: '#f2f2f2', // Not used anywhere yet but part of material design
    },
  },
  gray: {
    50: '#E6E6E6',
    100: '#D9D9D9',
    200: '#BFBFBF',
    300: '#A6A6A6',
    400: '#8C8C8C',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0D0D0D',
    950: '#000000',
  },
}

export const sidebarTitle: string = ''

// DEVELOPMENT: height: 80, top: 'top-[6.5em]'
// PRODUCTION: height: 60, top: 'top-28'
export const logoLoginClx = 'h-[100px] w-auto top-[6.5em]'
export const logoIndexClx = 'h-[42px] lg:h-[80px] w-auto'
export const logoSidebarClx = 'h-[64px] w-auto'

export const poweredBy = {
  text: 'AIONIC',
  url: 'https://aionicml.com/',
}

export const showIndexHeaderText = false

export const brandMetadata: Metadata = {
  title: `Occam by AIONIC`,
  description:
    'We build cutting-edge, custom AI solutions to meet your needs head-on.',
  openGraph: {
    type: 'website',
    url: 'https://aionicml.com/',
    title: 'Occam by AIONIC',
    description:
      'We build cutting-edge, custom AI solutions to meet your needs head-on.',
    siteName: 'Occam by AIONIC',
    images: [
      {
        url: `${APP_URL}/companyLogo.png`,
      },
    ],
  },
  twitter: {
    card: 'summary_large_image',
    site: 'https://aionicml.com/',
    images: `${APP_URL}/companyLogo.png`,
  },
  icons: [{ rel: 'icon', url: '/favicon.ico' }],
}
