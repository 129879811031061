import { createContext } from 'react'

import { defaultTheme } from '@/branding-config'

import { ThemeProps } from './theme.types'

export const ThemeContext = createContext<ThemeProps>({
  theme: defaultTheme,
  switchTheme: () => undefined,
})
