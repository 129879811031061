import { useContext } from 'react'

import { ThemeContext } from '@/context/theme/theme.context'

const useThemeState = () => {
  const context = useContext(ThemeContext)
  if (!Object.keys(ThemeContext).length) {
    throw new Error('ThemeContext must be used within its provider')
  }
  return context
}

export default useThemeState
